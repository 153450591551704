import { format, add, addHours, subHours, Locale, FirstWeekContainsDate } from "date-fns";

export const formatKor = (
  date: Date,
  formatstr: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: FirstWeekContainsDate;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
): string => {
  // date: UTC 시간
  // 9 시간을 더함.
  // ref: https://date-fns.org/v2.13.0/docs/add
  //const kor = add(date, {hours: 9});
  const kor = date;
  return format(kor, formatstr, options);
};


// 2020.11.04 테스트를 위하여 추가
export const formatKorTest = (
  date: Date,
  formatstr: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: FirstWeekContainsDate;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
): string => {
  // date: UTC 시간
  // 9 시간을 더함.
  // ref: https://date-fns.org/v2.13.0/docs/add
  const kor = add(date, { hours: 9 });
  //const kor = date;
  return format(kor, formatstr, options);
};
