import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";

class PageTitle extends Component<any, any> {
  render() {
    const {
      enablePageTitleSubheading,
      heading,
      subheading,
    } = this.props;

    return (

      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div>
              {heading}
              <div
                className={cx("page-title-subheading", { "d-none": !enablePageTitleSubheading })}>
                {subheading}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);
