import * as React from "react";

export const DeliveryTypeComp = ({ deliveryType }: { deliveryType: any }) => {
  switch (deliveryType) {
    case "NEAR": {
      return (<h5>
        <div className="badge badge-success">NEAR</div>
      </h5>);
    }
    case "FAR": {
      return (<h5>
        <div className="badge badge-info">FAR</div>
      </h5>);
    }

    case "MIDDLE": {
      return (<h5>
        <div className="badge badge-warning">MIDDLE</div>
      </h5>);
    }

    case "ADD": {
      return (<h5>
        <div className="badge badge-info">추가주문</div>
      </h5>);
    }

    case "BAEMIN": {
      return (<h5>
        <div className="badge badge-info">배민추가주문</div>
      </h5>);
    }

    case "BAEMIN_DELIVERY": {
      return (<h5>
          <div className="badge badge-success">배민앱주문</div>
        </h5>);
    }

    case "SANTA": {
      return (<h5>
        <div className="badge badge-info">산타</div>
      </h5>);
    }

    case "REJ": {
      return (<h5>
        <div className="badge badge-danger">REJ</div>
      </h5>);
    }
  }
  ;

  return (
    <div className="badge">{deliveryType}</div>
  );
};
