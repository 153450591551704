import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Squash as Hamburger } from "hamburger-react";

import cx from "classnames";

import {
  Button,
} from "reactstrap";

import {
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
} from "../../reducers/ThemeOptions";

class AppMobileMenu extends React.Component<any, any> {

  toggleMobileSidebar = () => {
    const { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };


  toggleMobileSmall = () => {
    const { enableMobileMenuSmall, setEnableMobileMenuSmall } = this.props;
    setEnableMobileMenuSmall(!enableMobileMenuSmall);
  };

  state = {
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false,
    activeMobile: true,
    active: false,
    mobile: false,
    activeSecondaryMenuMobile: false,
  };

  render() {
    const {
      enableMobileMenu,
    } = this.props;

    return (
      <Fragment>

        <div className="app-header__mobile-menu">
          <div onClick={this.toggleMobileSidebar}>
            <Hamburger
              toggled={!enableMobileMenu}
              onToggle={() => this.setState({ activeMobile: !this.state.activeMobile })}
              color="white"
              size={24}
            />
          </div>
        </div>
        <div className="app-header__menu">
            <span onClick={this.toggleMobileSmall}>
              <Button size="sm"
                      className={cx("btn-icon btn-icon-only", { active: this.state.activeSecondaryMenuMobile })}
                      color="primary"
                      onClick={() => this.setState({ activeSecondaryMenuMobile: !this.state.activeSecondaryMenuMobile })}>
                <div className="btn-icon-wrapper">
                  ⋮
                </div>
              </Button>
            </span>
        </div>
      </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({

  setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
  setEnableMobileMenuSmall: enable => dispatch(setEnableMobileMenuSmall(enable)),

});

export default connect(mapStateToProps, mapDispatchToProps)(AppMobileMenu);
