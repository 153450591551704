import React, { useCallback, useEffect, useState } from "react";

export const useIntervalUpdate = ({
                                    ms,
                                  }) => {
  const [dep, dispatch] = useState<{}>(Object.create(null));
  const forceUpdate = useCallback(
    (): void => {
      dispatch(Object.create(null));
    },
    [dispatch],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate();
    }, ms);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return {
    dep,
    forceUpdate,
  };
};
