import { useSelector } from "react-redux";
import { RootState } from "../../config/configureStore";
import { AuthState, AuthStatus } from "../../reducers/Auth";
import { useAsync } from "react-use";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Card, Form, FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Bounce, toast } from "react-toastify";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { ProductOrderService } from "../../services/ProductOrderService";
import { v4 as uuid } from "uuid";
import { format } from "date-fns";
import { formatKor } from "../../../../server/src/util/formatKor";
import { ko } from "date-fns/locale";
import dayjs from "dayjs";
import { useIntervalUpdate } from "../../hooks/useIntervalUpdate";
import { OrderFlowStatusComp } from "../ProductOrders/OrderFlowStatusComp";
import { DeliveryTypeComp } from "../ProductOrders/DeliveryTypeComp";
import { Link } from "react-router-dom";
import _ from "lodash";
import * as XLSX from "xlsx-js-style";
import * as FileSaver from "file-saver";
import { MessageService } from "../../services/MessageService";
import { ProductOrder } from "../ProductOrders/productOrder.type";
import { BaeminOrderStatusComp } from "./BaeminOrderStatusComp";
import { BaeminCancelOrderModal } from "./BaeminCancelOrderModal";

const DAY_TO_NUM = {
  SUNDAY: 0,
  MONDAY: 1,
  FRIDAY: 5,
  SATURDAY: 6,
};

const toastToError = (message: string) => {
  toast(message, {
    transition: Bounce,
    closeButton: true,
    autoClose: 5000,
    position: "bottom-center",
    type: "error",
  });
};

export type StockedPayedProductOrdersProps = {
  cardHeader?;
  viewDate?;
  FLOW?;
  deliveryType?;
  ProductOrderStatus?;
  storeManagerId?;
  OrderID?;
  OrdererName?;
  tong?;
  signature?;
  pagination;
};

export const BaeminOrders = ({
                                cardHeader = "",
                                viewDate = "0",
                                FLOW = "",
                                deliveryType = "",
                                ProductOrderStatus = "",
                                storeManagerId = "",
                                OrderID = "",
                                OrdererName = "",
                                tong = "",
                                signature = "",
                                pagination,
                              }: StockedPayedProductOrdersProps) => {

  const auth = useSelector<RootState, AuthState>(state => state.auth);
  const { dep, forceUpdate } = useIntervalUpdate({ ms: 180000 });
  const [lastValue, setLastValue] = useState(null);

  const [params, setParams] = useState({
    FLOW: "",
    viewDate: "0",
    marketCode: "",
    storeManagerId: storeManagerId || "",
    OrderID: OrderID || "",
    deliveryType: deliveryType || "",
    OrdererName: OrdererName || "",
    tong: tong || "",
    ProductOrderStatus: ProductOrderStatus || "",
    signature: signature || "",
  });

  const { loading, error, value } = useAsync(async () => {
    if (auth.status === AuthStatus.INACTIVE) {
      throw new Error();
    }
    const searchParams = {
      page: {
        per: 200,
        index: pagination.activePage === 0 ? pagination.activePage : pagination.activePage - 1,
      },
      filter: {
        deliveryType: params.deliveryType
          ? [params.deliveryType]
          : [
            "BAEMIN",
            "BAEMIN_DELIVERY",
          ],
        flow: params.FLOW.length !== 0 ? params.FLOW.split(",") : [],
        marketCode: params.marketCode ? [params.marketCode] : [],
        productOrderStatus: params.ProductOrderStatus ? [params.ProductOrderStatus] : [],
        storeId: params.storeManagerId ? [params.storeManagerId] : [],
        orderId: params.OrderID ? [params.OrderID] : [],
        ordererName: params.OrdererName ? [params.OrdererName] : [],
        bucketId: params.tong ? [params.tong] : [],
        signature: params.signature ? [params.signature] : [],
        viewDate: params.viewDate ? [params.viewDate] : [],
      },
    };

    return await ProductOrderService.getProductOrders(auth.token, searchParams);
  }, [pagination.activePage, params, dep]);

  useEffect(() => {
    if (value && value.data) {
      setLastValue(value);
    }
  }, [value]);

  const handleClickButtonToCheck = async (productOrderId: string) => {
    if (auth.status === AuthStatus.INACTIVE) {
      throw new Error();
    }
    try {
      await ProductOrderService.check(auth.token, productOrderId);
      toast("주문을 확인했습니다.", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "success",
      });

    } catch (e) {
      const message = e?.response?.data?.message || e.message;
      toast(message, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "error",
      });
    }
    forceUpdate();
  };

  const handleClickButtonToStock = async (productOrderId: string) => {
    if (auth.status === AuthStatus.INACTIVE) {
      throw new Error();
    }
    try {
      await ProductOrderService.stock(auth.token, productOrderId);
      toast("입고 완료했습니다.", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "success",
      });

    } catch (e) {
      const message = e?.response?.data?.message || e.message;
      toast(message, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "error",
      });
    }
    forceUpdate();
  };

  const handleClickButtonToShip = async (orderBucketId: number) => {
    if (auth.status === AuthStatus.INACTIVE) {
      throw new Error();
    }
    try {
      const shipResult = await ProductOrderService.ship(auth.token, orderBucketId);
      if (shipResult.data.result === "SUCCESS") {
        toast("배송 완료했습니다.", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "success",
        });
      } else if (shipResult.data.result === "NOT ALL") {
        toast(shipResult.data.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "warning",
        });
      } else {
        toast("에러발생", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
      }
    } catch (e) {
      const message = e?.response?.data?.message || e.message;
      toast(message, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "error",
      });
    }
    forceUpdate();
  };

  const handleClickButtonToCancel = async (productOrderId: string) => {
    if (auth.status === AuthStatus.INACTIVE) {
      throw new Error();
    }
    try {
      await ProductOrderService.revert(auth.token, productOrderId);
      toast("입고 취소하였습니다.", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "success",
      });
    } catch (e) {
      const message = e?.response?.data?.message || e.message;
      toast(message, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "error",
      });
    }
    forceUpdate();
  };

  const handleClickButtonToHold = async (productOrderId: string) => {
    if (auth.status === AuthStatus.INACTIVE) {
      throw new Error();
    }
    try {
      await ProductOrderService.hold(auth.token, productOrderId);
      toast("Hold처리 되었습니다.", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "success",
      });
    } catch (e) {
      const message = e?.response?.data?.message || e.message;
      toastToError(message);
    }
    forceUpdate();
  };

  const handleClickButtonToWaiting = async (productOrderId: string) => {
    if (auth.status === AuthStatus.INACTIVE) {
      throw new Error();
    }
    try {
      await ProductOrderService.waiting(auth.token, productOrderId);
      toast("Hold 해제 완료됐습니다.(Waiting)", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "success",
      });
    } catch (e) {
      const message = e?.response?.data?.message || e.message;
      toastToError(message);
    }
    forceUpdate();
  };

  const urgeMessage = async (storeManagerId: number) => {
    try {
      if (auth.status === AuthStatus.INACTIVE) {
        throw new Error();
      }
      const urgeResult = await MessageService.createUrgeMessage(auth.token, storeManagerId);
      if (urgeResult.data.status === "SUCCESS") {
        toast(urgeResult.data.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "success",
        });
      } else if (urgeResult.data.status === "NOT") {
        toast(urgeResult.data.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "warning",
        });
      }
    } catch (e) {
      const message = e?.response?.data?.message || e.message;
      toast(message, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "error",
      });
    }
    forceUpdate();
  };

  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [cancelModalProductOrderId, setCancelModalProductOrderId] = useState<string>("");
  const [cancelModalMaxQuantity, setCancelModalMaxQuantity] = useState<number>(0);

  const openCancelModal = useCallback((productOrderId, maxQuantity) => {
    setCancelModalProductOrderId(productOrderId);
    setCancelModalMaxQuantity(maxQuantity);
    setCancelModalOpen(true);
  }, []);

  useEffect(() => {
    if (value && value.data) {
      pagination.setPages(value.data.pages);
      if (value.data.pages < pagination.activePage) {
        pagination.goTo(1);
      }
    }
  }, [value]);

  const onClickPageButton = (page: number) => (e) => {
    e.preventDefault();
    pagination.goTo(page);
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    const name = target.name;

    setParams(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  /**
   * 2020.10.26 @dongwon
   * */
  const onReset = () => {
    setParams({
      FLOW: "",
      viewDate: "0",
      marketCode: "",
      storeManagerId: "",
      OrderID: "",
      deliveryType: "",
      OrdererName: "",
      tong: "",
      ProductOrderStatus: "",
      signature: "",
    });
  };


  const exportToCsv = (csvData, fileName) => {
    const csvFilteredData = _.map(csvData, (row) => {
      const row2 = _.omit(row, ["checked", "stocked", "flowEndTm", "OrderDate", "DeliveryDate", "orderBucketId", "ClaimStatus",
        "ClaimType", "LastChangedStatus", "otherProductID", "ProductIDKind", "LastChangedDate",
        "isAddressChanged", "Tel2", "ZipCode", "PlaceOrderDate", "createdAt", "updatedAt", "ClaimRequestDate", "CancelDetailedReason",
        "RefundStandbyStatus", "RequestChannel", "InflowPath", "DeliveryFeeAmount", "PaymentDate",
        "ProductOrderStatus", "PlaceOrderDate", "Market", "StoreManager", "PlaceOrderStatus", "salePrice"]);
      return {
        storeName: row.StoreManager.name,
        ...row2,
      };
    });

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const daystr = formatKor(new Date(), "yyyy-MM-dd", { locale: ko });
    const excelName = fileName + "-page-" + pagination.activePage + "-" + daystr;

    const ws1 = XLSX.utils.json_to_sheet(csvFilteredData);
    const wb = { Sheets: { data1: ws1 }, SheetNames: ["data1"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, excelName + fileExtension);
  };

  const render = (subRender) => {
    return (
      <Card className="main-card mb-3">
        <div className="card-header list-group-item-success">{cardHeader}
          <div className="btn-actions-pane-center">

            <Form className="form-inline">
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0 btn btn-primary active " style={{ display: "block" }}>
                <span>주문개수</span>
                <h4 style={{ marginBottom: 0 }}>
                  {(value?.data?.orderCount)
                    && ("총 " + String(value.data.orderCount) + " 개")
                  }
                </h4>
              </FormGroup>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0 btn btn-primary active " style={{ display: "block" }}>
                <span>상품주문개수</span>
                <h4 style={{ marginBottom: 0 }}>
                  {(value?.data?.count)
                    && ("총 " + String(value.data.count) + " 개")
                  }
                </h4>
              </FormGroup>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="checked">날짜</Label>
                <Input type="select" name="viewDate" id="viewDate" onChange={handleInputChange} value={params.viewDate} style={{ width: "60px" }}>
                  <option value={"0"}>오늘</option>
                  <option value={"1"}>어제</option>
                  <option value={"2"}>2일전</option>
                  <option value={"3"}>3일전</option>
                  <option value={"4"}>4일전</option>
                  <option value={"5"}>5일전</option>
                  <option value={"6"}>6일전</option>
                </Input>
              </FormGroup>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="checked">FLOW</Label>
                <Input type="select" name="FLOW" id="FLOW" onChange={handleInputChange} value={params.FLOW} style={{ width: "100px" }}>
                  <option value={""}>선택</option>
                  <option value={"NEW"}>NEW</option>
                  <option value={"WAITING"}>WAITING</option>
                  <option value={"STARTED,NOTIFIED"}>STARTED</option>
                  <option value={"CHECKED"}>CHECKED</option>
                  <option value={"STOCKED"}>STOCKED</option>
                  <option value={"PACKED"}>PACKED</option>
                  <option value={"SHIPPED"}>SHIPPED</option>
                  <option value={"HOLD"}>HOLD</option>
                  <option value={"ERROR"}>ERROR</option>
                </Input>
              </FormGroup>

              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="ProductOrderStatus">배민상태</Label>
                <Input type="select" name="ProductOrderStatus" id="ProductOrderStatus" onChange={handleInputChange} value={params.ProductOrderStatus} style={{ width: "100px" }}>
                  <option value={""}>선택</option>
                  <option value={"NEW"}>신규</option>
                  <option value={"PAYED"}>접수</option>
                  <option value={"DELIVERING"}>배달중</option>
                  <option value={"CANCELED"}>취소</option>
                  <option value={"DELIVERED"}>배달완료</option>
                </Input>
              </FormGroup>

              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="deliveryType">권역</Label>
                <Input type="select" name="deliveryType" id="deliveryType" onChange={handleInputChange}
                       value={params.deliveryType} style={{ width: "50px" }}>
                  <option value={""}>선택</option>
                  <option value={"BAEMIN_DELIVERY"}>배민앱</option>
                  <option value={"BAEMIN"}>추가주문</option>
                </Input>
              </FormGroup>

              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="marketCode" className="mr-sm-2">시장코드</Label>
                <Input type="text" name="marketCode" id="marketCode" placeholder="" onChange={handleInputChange} value={params.marketCode} style={{ width: "100px" }} />
              </FormGroup>

              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="storeManagerId" className="mr-sm-2">상점ID</Label>
                <Input type="text" name="storeManagerId" id="storeManagerId" placeholder="" onChange={handleInputChange} value={params.storeManagerId} style={{ width: "100px" }} />
              </FormGroup>

              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="OrdererName" className="mr-sm-2">주문자</Label>
                <Input type="text" name="OrdererName" id="OrdererName" placeholder="" onChange={handleInputChange} value={params.OrdererName} style={{ width: "70px" }} />
              </FormGroup>

              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="OrderID" className="mr-sm-2">주문ID</Label>
                <Input type="text" name="OrderID" id="OrderID" placeholder="" onChange={handleInputChange} value={params.OrderID} style={{ width: "100px" }} />
              </FormGroup>

              {/*<FormGroup className="mb-2 mr-sm-2 mb-sm-0">*/}
              {/*  <Label for="ProductID" className="mr-sm-2">상품ID</Label>*/}
              {/*  <Input type="text" name="ProductID" id="ProductID" placeholder="" onChange={handleInputChange} value={params.ProductID} style={{width: "100px"}}/>*/}
              {/*</FormGroup>*/}

              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="tong" className="mr-sm-2">통번호</Label>
                <Input type="text" name="tong" id="tong" placeholder="" onChange={handleInputChange} value={params.tong} style={{ width: "100px" }} />
              </FormGroup>

              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="signature">시그니처</Label>
                <Input type="select" name="signature" id="signature" onChange={handleInputChange} value={params.signature} style={{ width: "50px" }}>
                  <option value={""}>선택</option>
                  <option value={"O"}>O</option>
                  <option value={"X"}>X</option>
                </Input>
              </FormGroup>

              <Button className="btn-focus mr-4 btn-lg" style={{ fontSize: "14px" }} color={"info"} onClick={onReset}> Reset </Button>

              <Button className="btn-default mr-4 btn-lg" style={{ fontSize: "14px" }} color={"info"} onClick={
                (e) => {
                  e.stopPropagation();
                  exportToCsv(value.data.list, "productOrder");
                }
              }>xlsx</Button>
            </Form>

          </div>
        </div>
        {subRender()}
        <div className="d-block text-center card-footer">
          <TransitionGroup component="div">
            <CSSTransition
              classNames="TabsAnimation"
              appear={true}
              timeout={0}
              enter={false}
              exit={false}
            >
              <div>
                <div style={{ float: "right" }}>
                  <Button onClick={e => {
                    e.preventDefault();
                    window.scrollTo(0, 0);
                  }}>위로가기↑↑↑</Button>
                </div>
                <Pagination aria-label="Page navigation example" style={{ justifyContent: "center" }}>
                  {pagination.buttons.map((button) => {
                    if (button.previous) {
                      return (
                        <PaginationItem disabled={!button.clickable} key={uuid()}>
                          <PaginationLink aria-label=" " previous onClick={onClickPageButton(button.value)} />
                        </PaginationItem>
                      );
                    }
                    if (button.next) {
                      return (
                        <PaginationItem disabled={!button.clickable} key={uuid()}>
                          <PaginationLink aria-label=" " next onClick={onClickPageButton(button.value)} />
                        </PaginationItem>
                      );
                    }
                    return (
                      <PaginationItem active={button.active}
                                      disabled={!button.active && !button.clickable} key={uuid()}>
                        <PaginationLink aria-label=" " onClick={onClickPageButton(button.value)}>
                          {button.value}
                        </PaginationLink>
                      </PaginationItem>
                    );
                  })}
                </Pagination>
              </div>
            </CSSTransition>
          </TransitionGroup>
          <BaeminCancelOrderModal isOpen={cancelModalOpen} setOpen={setCancelModalOpen} productOrderId={cancelModalProductOrderId} maxQuantity={cancelModalMaxQuantity} forceUpdate={forceUpdate} />
        </div>
      </Card>
    );
  };

  const currentDate = format(new Date(), "yyyy-MM-dd");
  const currentDay = dayjs().tz("Asia/Seoul").get("day");

  const renderTable = (list: ProductOrder[]) => {
    return (
      <div className="table-responsive">
        <table className="align-middle mb-0 table table-borderless table-striped table-hover">
          <thead>
          <tr>
            <th className="text-center">상품 주문 ID
              <div />
              주문 ID
            </th>
            <th className="text-center">주문자</th>

            <th className="text-center">시장</th>
            <th className="text-center">상점</th>

            <th className="text-center">배송</th>
            <th className="text-center">주문통</th>

            <th className="text-center">FLOW</th>


            <th className="text-center">상품명
              <div />
              상품ID
            </th>
            <th className="text-center">수량</th>
            <th className="text-center">배민상태</th>
            <th className="text-center">가게메세지</th>
            <th className="text-center">라이더메세지</th>
            <th className="text-center">길안내메세지</th>

            <th className="text-center">전화번호</th>
            <th className="text-center">주소</th>

            <th className="text-center">최근변경</th>
            <th className="text-center">결제시점</th>

            <th className="text-center">주문확인처리</th>
            <th className="text-center">입고완료처리</th>
            <th className="text-center">발송처리하기</th>
            <th className="text-center">입고취소하기</th>
            <th className="text-center">재촉</th>
            <th className="text-center">홀드</th>
            <th className="text-center">취소</th>
          </tr>
          </thead>
          <tbody>
          {list.map((productOrder: ProductOrder) => {
            const linkUrl = `/store_managers/${productOrder?.StoreManager?.id}`;
            const marketTempHoliday = productOrder.Market?.baeminTempHoliday;


            let isTodayTempHoliday: boolean = false;
            if (marketTempHoliday === currentDate ||
              (currentDay === DAY_TO_NUM.SATURDAY && productOrder.Market?.saturdayOpen === "N") ||
              (currentDay === DAY_TO_NUM.SUNDAY && productOrder.Market?.sundayOpen === "N")
            ) {
              isTodayTempHoliday = true;
            }

            // @ts-ignore
            return (
              <tr key={productOrder.productOrderId}>
                <td className="text-center text-muted">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading">
                        <a onClick={e => {
                          e.preventDefault();
                          // window.open(`https://sell.smartstore.naver.com/o/orderDetail/productOrder/${productOrder.productOrderId}/popup`, "productOrderPopup", "width=800,height=1200");
                        }}
                           style={{
                             cursor: "pointer",
                           }}
                        >
                          {productOrder.productOrderId}
                        </a>
                      </div>
                      <div className="widget-subheading opacity-7">{productOrder.orderId}</div>
                    </div>
                  </div>
                </td>

                <td className="text-center">
                  {productOrder.ordererName}
                </td>

                <td className="text-center">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      {
                        isTodayTempHoliday ?
                          <>
                            <div className="widget-heading text-danger">{productOrder?.Market?.code || null}</div>
                            <div className="widget-subheading opacity-7 text-danger">{productOrder?.Market?.name || null}</div>
                            <div className="badge badge-pill badge-danger">휴무</div>
                          </>
                          :
                          <>
                            <div className="widget-heading">{productOrder?.Market?.code || null}</div>
                            <div className="widget-subheading opacity-7">{productOrder?.Market?.name || null}</div>
                          </>
                      }
                    </div>
                  </div>
                </td>

                <td className="text-center">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading">{productOrder?.StoreManager?.name || null}</div>

                      <div className="widget-subheading opacity-7">
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        <Link to={linkUrl}>
                          {productOrder?.StoreManager?.id || null}
                        </Link>
                      </div>

                    </div>
                  </div>
                </td>

                <td className="text-center">
                  <DeliveryTypeComp deliveryType={productOrder.deliveryType} />
                </td>

                <td className="text-center">
                  {
                    (productOrder?.bucketId)
                      ? <div className="btn-transition btn btn-outline-success"><h5> {productOrder.bucketId} </h5></div>
                      : <div> 미 </div>
                  }
                </td>

                <td className="text-center">
                  <OrderFlowStatusComp productOrderInfo={productOrder} />
                </td>


                <td>
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" data-toggle="tooltip" data-placement="top" title={productOrder.productNaverName}>
                          {productOrder.productName}
                        </div>
                        <div className="widget-subheading opacity-7">{productOrder.productId}</div>
                        {
                          (productOrder?.optionName)
                            ? <div>{productOrder.optionName}</div>
                            : <div></div>
                        }
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-center">{productOrder.quantity}</td>
                <td className="text-center">
                  <BaeminOrderStatusComp productOrderInfo={productOrder} />
                </td>

                <td>
                  {
                    (productOrder.shopMemo) ?
                      "O" :
                      "X"
                  }
                </td>

                <td>
                  {
                    (productOrder.shippingMemo) ?
                      "O" :
                      "X"
                  }
                </td>

                <td>
                  {
                    (productOrder.doorMemo) ?
                      "O" :
                      "X"
                  }
                </td>

                <td>
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading">{productOrder.tel}</div>
                        <div className="widget-subheading opacity-7">{productOrder.subTel}</div>
                      </div>
                    </div>
                  </div>
                </td>

                <td>
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading">{productOrder.baseAddress}</div>
                      </div>
                    </div>
                  </div>
                </td>

                {/*<td className="text-center">*/}
                {/*  <PlaceOrderStatusComp productOrder={productOrder}/>*/}
                {/*</td>*/}

                <td className="text-center">
                  {dayjs(productOrder.lastChangedDate).tz("Asia/Seoul").format("MM-DD HH:mm")}
                </td>
                <td className="text-center">
                  {dayjs(productOrder.paymentDate).tz("Asia/Seoul").format("MM-DD HH:mm")}
                </td>

                {/* 2021.08.10 @예병수 제거 */}
                {/* <td className="text-center">
                    {!productOrder.flow?.startsWith("ERR")
                      && productOrder?.PlaceOrderStatus !== "OK"
                      && (<Button className="mb-2 mr-2 btn-warning" onClick={() => handleClickButtonToPlace(productOrder.ProductOrderID)}>발주</Button>)
                    }
                  </td> */}

                <td className="text-center">
                  {!productOrder.flow?.startsWith("ERR")
                    && (productOrder.flow === "NEW" || productOrder.flow === "WAITING" || productOrder.flow === "STARTED" || productOrder.flow === "NOTIFIED")
                    && (<Button className="mb-2 mr-2 btn-focus" onClick={() => handleClickButtonToCheck(productOrder.productOrderId)}>주문 확인</Button>)
                  }

                </td>
                <td className="text-center">
                  {!productOrder.flow?.startsWith("ERR")
                    && productOrder.flow === "CHECKED"
                    && (<Button className="mb-2 mr-2 btn-focus" onClick={() => handleClickButtonToStock(productOrder.productOrderId)}>입고 완료</Button>)
                  }
                </td>
                <td className="text-center">
                  {!productOrder.flow?.startsWith("ERR")
                    && productOrder.productOrderStatus === "PAYED"
                    && productOrder?.flow === "STOCKED"
                    && (<Button className="mb-2 mr-2 btn-warning" onClick={() => handleClickButtonToShip(productOrder.orderBucketId)}>발송</Button>)
                  }
                </td>
                <td className="text-center">
                  {!productOrder.flow?.startsWith("ERR")
                    && productOrder.productOrderStatus === "PAYED"
                    && productOrder.flow === "STOCKED"
                    && (<Button className="mb-2 mr-2 btn-warning" onClick={() => handleClickButtonToCancel(productOrder.productOrderId)}>입고취소</Button>)
                  }
                </td>
                <td className="text-center">
                  {!productOrder.flow?.startsWith("ERR")
                    && (productOrder.flow === "STARTED" || productOrder.flow === "NOTIFIED")
                    && (<Button className="mb-2 mr-2 btn-focus" onClick={() => urgeMessage(productOrder.StoreManager.id)}>재촉</Button>)
                  }

                </td>
                <td className="text-center">
                  {!productOrder.flow?.startsWith("ERR")
                    && productOrder.productOrderStatus === "PAYED"
                    && (productOrder.flow === "NEW" || productOrder.flow === "WAITING")
                    && (<Button className="mb-2 mr-2 btn-dark" onClick={() => (handleClickButtonToHold(productOrder.productOrderId))}>홀드</Button>)
                    || !productOrder.flow?.startsWith("ERR")
                    && productOrder.flow === "HOLD"
                    && productOrder.productOrderStatus === "PAYED"
                    && (<Button className="mb-2 mr-2 btn-light" onClick={() => (handleClickButtonToWaiting(productOrder.productOrderId))}>해제</Button>)
                  }

                </td>
                <td className="text-center">
                  {!productOrder.flow?.startsWith("ERR")
                    && productOrder.flow !== "SHIPPED"
                    && productOrder.flow !== "STOCKED"
                    && (productOrder.productOrderStatus === "NEW" || productOrder.productOrderStatus === "RECEIPT" || productOrder.productOrderStatus === "PAYED")
                    && (<Button className="mb-2 mr-2 btn-warning" onClick={() => openCancelModal(productOrder.productOrderId, productOrder.quantity)}>취소</Button>)
                  }
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    );
  };

  if (loading) {
    if (lastValue) {
      return (
        render(() => (
          renderTable(lastValue.data.list)
        ))
      );
    }
    return (
      render(() => (
        <>
          <div>Loading...</div>
        </>
      ))
    );
  }
  if (error) {
    const message = (error as any)?.response?.data?.message || error.message;
    toast(message, {
      transition: Bounce,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-center",
      type: "error",
    });
    return (
      render(() => (
        <>
          <div>Error...</div>
        </>
      )));
  }
  const { list } = value.data;


  return (
    render(() => (
      renderTable(list)
    ))
  );
};
