import React, { useState } from "react";
import { Button, Col, Form, FormGroup, Label, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { LoginService } from "../../services/LoginService";
import { AuthActionCreator } from "../../reducers/Auth";
import { Bounce, toast } from "react-toastify";
import appbanner from "../../images/appbanner1024.png";

export const Login = () => {
  const [state, setState] = useState({
    userId: "",
    password: "",
  });
  const dispatch = useDispatch();
  const onSubmit = async (e) => {
    e.preventDefault();

    if (state.userId.length < 3) {
      return toast("Enter ID", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "error",
      });
    }

    if (state.password.length < 3) {
      return toast("Enter password", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "error",
      });
    }

    try {
      const result = await LoginService.getAuthToken(state);
      toast("로그인에 성공하셨습니다.", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "success",
      });
      dispatch(AuthActionCreator.activate({ token: result.data.token, userId: state.userId }));
    } catch (e) {
      const message = e?.response?.data?.message || e.message;
      toast(message, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "error",
      });
      dispatch(AuthActionCreator.deactivate());
    }
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    const name = target.name;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <div className="app-main">
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Row>
            <Col md="12">
              <img alt="appbanner" src={String(appbanner)} />
            </Col>
          </Row>

          <Row style={{ marginTop: 30 }}>
            <Col md="12">
              <h1> Admin for FreshMentor 동네시장장보기</h1>
            </Col>
          </Row>

          <Row style={{ marginTop: 30 }}>
            <Col md="12">
              <Form onSubmit={onSubmit}>
                <FormGroup className="input-group input-group-lg ">
                  <div className="input-group-prepend">
                    <span className="input-group-text" style={{ width: 130 }}>
                      <Label for="loginUserId">Admin ID</Label>
                    </span>
                  </div>

                  <input
                    className="input-group-lg"
                    style={{ width: 300, fontSize: "1.5em" }}
                    type="text"
                    name="userId"
                    id="loginUserId"
                    placeholder=""
                    onChange={handleInputChange}
                  />
                </FormGroup>

                <FormGroup className="input-group input-group-lg ">
                  <div className="input-group-prepend">
                    <span className="input-group-text" style={{ width: 130 }}>
                      <Label for="loginPassword">Password</Label>
                    </span>
                  </div>

                  <input
                    className="input-group-lg"
                    style={{ width: 300, fontSize: "1.5em" }}
                    type="password"
                    name="password"
                    id="loginPassword"
                    placeholder=""
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <Button className="mb-2 mr-2 btn btn-lg btn-success">
                  {" "}
                  <h3>Login</h3>{" "}
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
