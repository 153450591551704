import { AnyAction, applyMiddleware, combineReducers, compose, createStore, Store } from "redux";
import ThemeOptions from "../reducers/ThemeOptions";
import { authReducer, AuthState } from "../reducers/Auth";
import { loadState } from "./loadState";
import { saveState } from "./saveState";
import { navReducer } from "../reducers/NavItems";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export type RootState = {
  auth: AuthState;
}
export const rootReducer: (state: RootState, action: AnyAction) => RootState = combineReducers<RootState>({
  auth: authReducer,
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  ThemeOptions: (ThemeOptions),
  navReducer: navReducer,
});

const enhancer = composeEnhancers(applyMiddleware());
const store: Store<RootState> = createStore(rootReducer, loadState(), enhancer);
store.subscribe(() => {
  saveState({
    auth: store.getState().auth,
  });
});


export default store;
