import Axios from "axios";
import { ProductOrder } from "../Pages/ProductOrders/productOrder.type";
import FileDownload from "js-file-download";
import qs from "qs";

export const ProductOrderService = {
  getProductOrders: (token, searchParams) => {
    return Axios.get<{ list: ProductOrder[]; pages: number; count: number; orderCount: number }>("/api/admin/product_order/list", {
      headers: {
        authorization: `Bearer ${token}`,
      },
      params: searchParams,
      paramsSerializer: (params) => qs.stringify(params),
    });
  },
  getLatedList: (token) => {
    return Axios.get("/api/admin/product_order/list/lated", {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  getNewOrderList: (token) => {
    return Axios.get("/api/admin/product_order/list/new", {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  check: (token, productOrderId) => {
    return Axios.post(`/api/admin/product_order/${productOrderId}/check`, {}, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  revert: (token, productOrderId) => {
    return Axios.post(`/api/admin/product_order/${productOrderId}/revert`, {}, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  stock: (token, productOrderId) => {
    return Axios.post(`/api/admin/product_order/${productOrderId}/stock`, {}, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  place: (token, productOrderId) => {
    return Axios.post(`/api/admin/product_order/${productOrderId}/place`, {}, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  ship: (token, orderBucketId) => {
    return Axios.post(`/api/admin/order_bucket/${orderBucketId}/ship`, {}, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  hold: (token, productOrderId) => {
    return Axios.post(`/api/admin/product_order/${productOrderId}/hold`, {}, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  waiting: (token, productOrderId) => {
    return Axios.post(`/api/admin/product_order/${productOrderId}/waiting`, {}, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  cancelBaeminOrder: (token, productOrderId, quantity) => {
    return Axios.post(`/api/admin/product_order/${productOrderId}/baemin/cancel`, { quantity }, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
};
