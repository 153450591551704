import React from "react";

import dayjs from "dayjs";
import dayjsTimezone from "dayjs/plugin/timezone";
import dayjsUtc from "dayjs/plugin/utc";
import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import "animate-sass/_animate.scss";
import Main from "./DemoPages/Main";
import { Provider } from "react-redux";
import store from "./config/configureStore";
import Axios from "axios";
import { AuthActionCreator } from "./reducers/Auth";

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);

Axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    if (error?.message?.includes("401")) {
      store.dispatch(AuthActionCreator.deactivate());
    }
    return Promise.reject(error);
  },
);

export const App = () => {
  return (
    <Provider store={store}>
      <HashRouter>
        <Main />
      </HashRouter>
    </Provider>
  );
};
