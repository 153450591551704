import { Redirect, Route } from "react-router-dom";
import React, { Fragment, lazy, Suspense } from "react";

import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../config/configureStore";
import { AuthState, AuthStatus } from "../../reducers/Auth";
import { Login } from "../../Pages/Login/Login";
import BaeminOrdersPage from "../../Pages/BaeminOrders/BaeminOrdersPage";

const ProductOrdersPage = lazy(() => import("../../Pages/ProductOrders/ProductOrdersPage"));
const ProductOptions = lazy(() => import("../../Pages/ProductOptions/ProductOptions"));
const Provides = lazy(() => import("../../Pages/Provides/Provides"));
const NewProvideHistory = lazy(() => import("../../Pages/Provides/NewProvideHistory"));
const StoreManagers = lazy(() => import("../../Pages/StoreManagers/StoreManagers"));
const Markets = lazy(() => import("../../Pages/Markets/Markets"));
const Tongs = lazy(() => import("../../Pages/Tongs/TongRoutes"));
const DashboardRoutes = lazy(() => import("../../Pages/Dashboard/DashboardRoutes"));
const Boards = lazy(() => import("../../Pages/Boards/Boards"));
const TestPage = lazy(() => import("../../Pages/TestPage/TestPage"));
const ClaimMain = lazy(() => import("../../Pages/Claims/ClaimMain"));
const NewDataProductPage = lazy(() => import("../../Pages/Data/DataProductPage"));
const DataOrderPayment = lazy(() => import("../../Pages/Data/DataOrderPayment"));
const MarketingPage = lazy(() => import("../../Pages/Marketing/MarketingPage"));
const Charts = lazy(() => import("../../Pages/Charts/Charts"));
const DataOrderInfos = lazy(() => import("../../Pages/Data/DataOrderInfos"));
const ProductPage = lazy(() => import("../../Pages/Products/ProductPage"));
const BaeminProductPage = lazy(() => import("../../Pages/BaeminProducts/BaeminProductPage"));
const AdditionalOrdersPage = lazy(
  () => import("../../Pages/AdditionalOrders/AdditionalOrdersPage"),
);
const HoldOrdersPage = lazy(() => import("../../Pages/HoldOrders/HoldOrdersPage"));
const StoreEventPage = lazy(() => import("../../Pages/StoreEvents/StoreEventRoutes"));
const StoreHolidayMain = lazy(() => import("../../Pages/StoreHolidays/StoreHolidayMain"));
const MarketEventMain = lazy(() => import("../../Pages/MarketEvents/MarketEventMain"));
const HolidayMain = lazy(() => import("../../Pages/Holiday/HolidayMain"));
const MonthlyAccountMain = lazy(() => import("../../Pages/MonthlyAccount/MonthlyAccountMain"));

export const ConditionalRoute = ({ render, condition, redirectPath, ...rest }) => {
  if (condition) {
    return <Route {...rest} render={render} />;
  }
  return (
    <Route
      {...rest}
      render={({ ...props }) => (
        <Redirect
          to={{
            pathname: redirectPath,
            state: { from: props.location },
          }}
        />
      )}
    />
  );
};

export const PublicRoute = ({ render: Component, ...rest }) => {
  return <ConditionalRoute render={Component} condition={true} redirectPath="/" {...rest} />;
};

export const PrivateRoute = ({ render: Component, ...rest }) => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  return (
    <ConditionalRoute
      render={Component}
      condition={auth.status === AuthStatus.ACTIVE}
      redirectPath="/"
      {...rest}
    />
  );
};

const AppMain = () => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  if (auth.status !== AuthStatus.ACTIVE) {
    return (
      <>
        <Login />
        <ToastContainer />
      </>
    );
  }
  return (
    <Fragment>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/product_orders" exact={true} component={ProductOrdersPage} />
      </Suspense>

       <Suspense
         fallback={
           <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
         }
       >
        <Route path="/baemin_orders" exact={true} component={BaeminOrdersPage} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/products" component={ProductPage} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/product_options" component={ProductOptions} />
      </Suspense>

       <Suspense
         fallback={
           <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
         }
       >
        <Route path="/baemin_products" component={BaeminProductPage} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/provides" component={Provides} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/provide/history" component={NewProvideHistory} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/additional-orders" component={AdditionalOrdersPage} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/hold-orders" component={HoldOrdersPage} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/store_managers" component={StoreManagers} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/store-events" component={StoreEventPage} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/tongs" component={Tongs} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/markets" component={Markets} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/dashboard" component={DashboardRoutes} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/boards" component={Boards} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/data/order-info" component={DataOrderInfos} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/data/product" component={NewDataProductPage} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/data/order/time" component={DataOrderPayment} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/marketings" component={MarketingPage} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/tests" component={TestPage} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/claims" component={ClaimMain} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/data/charts" component={Charts} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/store-holidays" component={StoreHolidayMain} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/market-events" component={MarketEventMain} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/holiday" component={HolidayMain} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the Components examples. This
                  wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/monthly-account" component={MonthlyAccountMain} />
      </Suspense>

      <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
