import * as React from "react";
import { Fragment, useEffect } from "react";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import PageTitle from "../../Layout/AppMain/PageTitle";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Card,
  Col,
  Row,
} from "reactstrap";
import { usePagination } from "../../hooks/usePagination";
import { useHistory } from "react-router-dom";
import * as qs from "qs";
import { BaeminOrders } from "./BaeminOrders";

const BaeminOrdersPage = () => {
  const history = useHistory();
  const {
    productOrdersPage,
  } = qs.parse(history.location.search, { ignoreQueryPrefix: true });

  const pagination = usePagination({
    initialPage: Number(productOrdersPage || 1),
  });

  useEffect(() => {
    history.push({
      pathname: history.location.pathname,
      search: `?productOrdersPage=${pagination.activePage}`,
    });
  }, [pagination.activePage]);

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Fragment>
              <TransitionGroup component="div">
                <CSSTransition
                  classNames="TabsAnimation"
                  appear={true}
                  timeout={0}
                  enter={false}
                  exit={false}
                >
                  <div>
                    <PageTitle
                      heading="배민 주문 목록 페이지"
                      subheading="전체 목록"
                    />
                    <Row>
                      <Col md="12">
                        <Card className="main-card mb-3">
                          <BaeminOrders pagination={pagination} />
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </Fragment>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BaeminOrdersPage;
