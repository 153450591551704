import Axios from "axios";
import { MessageViewMongoDb } from "../../../server/src/types/message.types";

export const MessageService = {
  getDashboardMessages: (token) => {
    return Axios.get("/api/admin/message/store_manager/list", {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  getUnreadMessagesByAdmin: (token) => {
    return Axios.get<{ list: MessageViewMongoDb[] }>("/api/admin/message/store_manager/unread_messages", {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  getMessagesByStoreId: (token, storeId) => {
    return Axios.get<{ list: MessageViewMongoDb[] }>(`/api/admin/message/store_manager/${storeId}/list`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  getMessagesByStoreIdAndType: (token, storeId, type) => {
    return Axios.get<{ list: MessageViewMongoDb[] }>(`/api/admin/message/store_manager/${storeId}/list/${type}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  createMessageByAdmin: (token, storeId, form) => {
    return Axios.post(`/api/admin/message/store_manager/${storeId}`, {
        content: form.content,
        messageType: form.messageType,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
  },
  createUrgeMessage: (token, storeId) => {
    return Axios.post(`/api/admin/message/store_manager/urge/${storeId}`, {}, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  deleteMessage: (token, id) => {
    return Axios.delete(`/api/admin/message/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
};
