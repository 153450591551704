import Axios from "axios";

export const LoginService = {
  getAuthToken: (form) => {
    return Axios.post("/api/admin/login", form);
  },
  signUpMarket: (token, form) => {
    return Axios.post("/api/admin/market", form, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
}; 
