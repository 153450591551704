import { ProductOrder } from "../ProductOrders/productOrder.type";
import * as React from "react";

export const BaeminOrderStatusComp = ({
                                         productOrderInfo,
                                       }: {
  productOrderInfo: ProductOrder;
}) => {

  switch (productOrderInfo.productOrderStatus) {
    case "NEW": {
      return (<h5>
        <div className="badge badge-secondary">신규</div>
      </h5>);
    }
    case "RECEIPT": {
      return (<h5>
        <div className="badge badge-primary">접수</div>
      </h5>);
    }
    case "PAYED": {
      return (<h5>
            <div className="badge badge-primary">접수</div>
          </h5>);
    }
    case "DELIVERY": {
      return (<h5>
        <div className="badge badge-info">배달중</div>
      </h5>);
    }
    case "DELIVERING": {
      return (<h5>
          <div className="badge badge-info">배달중</div>
        </h5>);
    }
    case "CANCEL": {
      return (<h5>
            <div className="badge badge-warning">취소</div>
          </h5>);
    }
    case "CANCELED": {
      return (<h5>
          <div className="badge badge-warning">취소</div>
        </h5>);
    }
    case "COMPLETE": {
      return (<h5>
        <div className="badge badge-success">배달완료</div>
      </h5>);
    }
    case "DELIVERED": {
      return (<h5>
          <div className="badge badge-success">배달완료</div>
        </h5>);
    }

  }
  return (
    <div className="badge">{productOrderInfo.productOrderStatus}</div>
  );
};
