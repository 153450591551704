import { RootState } from "./configureStore";

export const saveState = (state: Partial<RootState>) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch {
    // ignore write errors
  }
};
