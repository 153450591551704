import { ActionType } from "typesafe-actions";
import { Reducer } from "redux";

enum AuthActionType {
  ACTIVATE = "auth/ACTIVATE",
  DEACTIVATE = "auth/DEACTIVATE",
}

export const AuthActionCreator = {
  activate: ({ token, userId }) => ({
    payload: {
      token,
      userId,
    },
    type: AuthActionType.ACTIVATE,
  }) as const,
  deactivate: () => ({
    payload: {},
    type: AuthActionType.DEACTIVATE,
  }) as const,
};

export enum AuthStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type AuthState = ActivatedAuth | DeactivatedAuth;

export type ActivatedAuth = {
  status: AuthStatus.ACTIVE;
  token: string;
  userId: string;
}

export type DeactivatedAuth = {
  status: AuthStatus.INACTIVE;
}

const initialState: AuthState = {
  status: AuthStatus.INACTIVE,
};

export type AuthAction = ActionType<typeof AuthActionCreator>;

export const authReducer: Reducer<AuthState, AuthAction> = (
  authState: AuthState = initialState,
  action: AuthAction,
) => {
  switch (action.type) {
    case AuthActionType.ACTIVATE: {
      return {
        status: AuthStatus.ACTIVE,
        token: action.payload.token,
        userId: action.payload.userId,
      };
    }
    case AuthActionType.DEACTIVATE: {
      return {
        status: AuthStatus.INACTIVE,
      };
    }
  }
  return authState;
};
