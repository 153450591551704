import React, { Fragment } from "react";

import {
  Button,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
  UncontrolledTooltip,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { AuthActionCreator, AuthState, AuthStatus } from "../../../reducers/Auth";
import { RootState } from "../../../config/configureStore";

const UserBox = () => {
  const dispatch = useDispatch();
  const auth = useSelector<RootState, AuthState>(state => state.auth);
  if (auth.status === AuthStatus.INACTIVE) {
    return null;
  }

  const logout = (e) => {
    e.preventDefault();
    dispatch(AuthActionCreator.deactivate());
  };

  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  ⋮
                </DropdownToggle>
                <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                  <Nav vertical>
                    <NavItem className="nav-item-header">
                      My Account
                    </NavItem>
                    <NavItem>
                      <NavLink href="#" onClick={logout}>
                        Logout
                      </NavLink>
                    </NavItem>
                  </Nav>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div className="widget-content-left  ml-3 header-user-info">
              <div className="widget-heading">
                {auth.userId}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserBox;
