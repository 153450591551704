import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import { useResizeDetector } from "react-resize-detector";
import AppMain from "../../Layout/AppMain";

// @TODO 디렉토리 이전 또는 코드 정리 필요
const Main = (props) => {
  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
  } = props;

  const { width, ref } = useResizeDetector({
    handleWidth: true,
  });

  return (
    <Fragment>
      <div
        ref={ref}
        className={cx(
          "app-container app-theme-" + colorScheme,
          { "fixed-header": enableFixedHeader },
          { "fixed-sidebar": enableFixedSidebar || width < 1250 },
          { "fixed-footer": enableFixedFooter },
          { "closed-sidebar": enableClosedSidebar || width < 1250 },
          { "closed-sidebar-mobile": closedSmallerSidebar || width < 1250 },
          { "sidebar-mobile-open": enableMobileMenu },
        )}
      >
        <AppMain />
      </div>
    </Fragment>
  );
};

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));
