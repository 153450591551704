import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ProSideBar from "react-pro-sidebar";

const Sidebar = () => {
  const navItems = useSelector<any>(state => state.navReducer);
  const dispatch = useDispatch();

  return (
    <ProSideBar.Sidebar>
      <ProSideBar.Menu>
        {
          navItems &&
          // @ts-ignore
          navItems.map((state, state_index) => {
            return (
              // 상위 카테고리에 이름, defaultOpen 설정
              <ProSideBar.SubMenu key={state_index} defaultOpen={state.open} label={state.title}
                                  onOpenChange={() => dispatch({ type: !state.open, payload: { title: state.title } })}>
                {
                  state.contents.map((content, content_index) => {
                    return (
                      // 하위카테고리에 이름, 링크 설정
                      <ProSideBar.MenuItem key={content_index} href={content.link}>
                        {content.menuName}
                      </ProSideBar.MenuItem>
                    );
                  })
                }
              </ProSideBar.SubMenu>
            );
          })
        }
      </ProSideBar.Menu>
    </ProSideBar.Sidebar>
  );
};

// export default withRouter(Sidebar);
export default Sidebar;
