import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Squash as Hamburger } from "hamburger-react";

import AppMobileMenu from "../AppMobileMenu";

import { setEnableClosedSidebar, setEnableMobileMenu, setEnableMobileMenuSmall } from "../../reducers/ThemeOptions";

class HeaderLogo extends React.Component<any, any> {
  toggleEnableClosedSidebar = () => {
    const { enableClosedSidebar, setEnableClosedSidebar } = this.props;
    setEnableClosedSidebar(!enableClosedSidebar);
  };

  state = {
    active: false,
    mobile: false,
    activeSecondaryMenuMobile: false,
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false,
  };

  render() {
    const {
      enableClosedSidebar,
    } = this.props;

    const {} = this.state;

    return (
      <Fragment>
        <div className="app-header__logo">
          {/*<div className="logo-src"/>*/}
          <div className="header__pane ml-auto">
            <div onClick={this.toggleEnableClosedSidebar}>
              <Hamburger
                toggled={!enableClosedSidebar}
                onToggle={() => this.setState({ active: !this.state.active })}
                color="white"
                size={24}
              />
            </div>
          </div>
        </div>
        <AppMobileMenu />
      </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({

  setEnableClosedSidebar: enable => dispatch(setEnableClosedSidebar(enable)),
  setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
  setEnableMobileMenuSmall: enable => dispatch(setEnableMobileMenuSmall(enable)),

});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);
