import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Form,
  FormGroup,
  ModalHeader,
  Label,
  Input,
  ModalFooter,
  Button,
  ModalBody,
} from "reactstrap";
import { RootState } from "../../config/configureStore";
import { AuthState, AuthStatus } from "../../reducers/Auth";
import { toast, Bounce } from "react-toastify";
import { ProductOrderService } from "../../services/ProductOrderService";


export const BaeminCancelOrderModal = ({
                                     isOpen,
                                     setOpen,
                                     productOrderId,
                                     maxQuantity,
                                     forceUpdate,
                                   }) => {
  const auth = useSelector<RootState, AuthState>(state => state.auth);

  const toggle = useCallback(() => {
    setOpen(false);
  }, []);

  const [params, setParams] = useState({
    quantity: "",
  });

  const onSubmit = async (e) => {
    if (auth.status === AuthStatus.INACTIVE) {
      throw new Error();
    }
    e.preventDefault();

    if (!params.quantity) {
      return toast("취소 수량을 입력해주세요.", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "error",
      });
    }
    try {
      await ProductOrderService.cancelBaeminOrder(auth.token, productOrderId, Number(params.quantity));
      toast("수정되었습니다.", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "success",
      });
      forceUpdate();
    } catch (e) {
      const message = e?.response?.data?.message || e.message;
      toast(message, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "error",
      });
    }
    toggle();
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    const name = target.name;

    setParams(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={onSubmit}>
        <ModalHeader className="list-group-item-warning">배민 주문 취소</ModalHeader>
        <ModalBody>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0 ">
            <Label for="quantity" className="mr-sm-2">취소 수량</Label>
            <Input type="number" min={1} max={maxQuantity} name="quantity" id="quantity" placeholder="" onChange={handleInputChange}
                   value={params.quantity} />
          </FormGroup>
          <div>
            * 최대 { maxQuantity }개 취소 가능합니다.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary">적용</Button>
          <Button color="secondary" onClick={toggle}>취소</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
