// sidebar의 item들
// 실제 어드민에서 보여주고자 하는 순서대로 작성해야 한다.
const initialNavState = [
  {
    title: "대시보드",
    open: false,
    contents: [
      {
        link: "#/dashboard",
        menuName: "대시보드",
      },
    ],
  },
  {
    title: "주문",
    open: false,
    contents: [
      {
        link: "#/product_orders",
        menuName: "상품 주문 내역",
      },
      {
        link: "#/baemin_orders",
        menuName: "배민 주문 내역",
      },
      {
        link: "#/additional-orders",
        menuName: "추가 주문 목록",
      },
      {
        link: "#/hold-orders",
        menuName: "홀드 주문 목록",
      },
    ],
  },
  {
    title: "상품",
    open: false,
    contents: [
      {
        link: "#/products",
        menuName: "상품 목록",
      },
      {
        link: "#/product_options",
        menuName: "옵션 상품 목록",
      },
      {
        link: "#/baemin_products",
        menuName: "배민 상품 목록",
      },
      {
        link: "#/provides",
        menuName: "공급가 변경",
      },
      {
        link: "#/provide/history",
        menuName: "공급가 변경 이력",
      },
    ],
  },
  {
    title: "시장/상점",
    open: false,
    contents: [
      {
        link: "#/store_managers",
        menuName: "상점 목록",
      },
      {
        link: "#/tongs",
        menuName: "배달통 목록",
      },
      {
        link: "#/markets",
        menuName: "시장 목록",
      },
      {
        link: "#/store-holidays",
        menuName: "상점 휴무일 목록",
      },
      {
        link: "#/holiday",
        menuName: "장보기 휴무일 목록",
      },
    ],
  },
  {
    title: "게시판",
    open: false,
    contents: [
      {
        link: "#/boards",
        menuName: "게시판",
      },
    ],
  },
  {
    title: "클레임",
    open: false,
    contents: [
      {
        link: "#/claims",
        menuName: "클레임",
      },
    ],
  },
  {
    title: "데이터",
    open: false,
    contents: [
      {
        link: "#/data/order-info",
        menuName: "주문 데이터",
      },
      {
        link: "#/data/product",
        menuName: "상품 데이터",
      },
      // {
      //   link: "#/data/order/time",
      //   menuName: "시간별 주문수(미완성)",
      // },
      {
        link: "#/monthly-account",
        menuName: "월별 손익 데이터",
      },
    ],
  },
  {
    title: "마케팅",
    open: false,
    contents: [
      {
        link: "#/marketings",
        menuName: "마케팅",
      },
      {
        link: "#/market-events",
        menuName: "시장행사",
      },
      {
        link: "#/store-events",
        menuName: "상점행사 목록",
      },
    ],
  },
  {
    title: "테스트",
    open: false,
    contents: [
      {
        link: "#/tests",
        menuName: "테스트 기능",
      },
    ],
  },
];

const OPEN = true;
const CLOSE = false;

// sidebar 상태관리
export const navReducer = (state = initialNavState, action) => {
  switch (action.type) {
    // ProSidebar의 defaultOpen 속성에 넣을 값: OPEN(true) || CLOSE(false)
    case OPEN:
      // initialState 복사본
      const copiedStateToOpen = [...state];

      for (let i = 0; i < copiedStateToOpen.length; i++) {
        // 열고자 하는 navItem의 이름이 같을 경우 defaultOpen 속성 true로 수정
        if (copiedStateToOpen[i].title === action.payload.title) {
          copiedStateToOpen[i].open = true;
        }
      }
      return copiedStateToOpen;
    case CLOSE:
      // initialState 복사본
      const copiedStateToClose = [...state];

      for (let i = 0; i < copiedStateToClose.length; i++) {
        if (copiedStateToClose[i].title === action.payload.title) {
          copiedStateToClose[i].open = false;
        }
      }
      return copiedStateToClose;
    default:
      return state;
  }
};
