import React from "react";

function* range(lower: number, upper: number) {
  if (lower > upper) {
    return;
  }
  yield lower;
  yield* range(lower + 1, upper);
}

type Button = {
  clickable: boolean;
  value: number;
  previous: boolean;
  next: boolean;
  active: boolean;
}

export const usePagination = ({
                                initialPage,
                              }, deps = []) => {
  const [activePage, setActivePage] = React.useState(initialPage);
  const [pages, setPages] = React.useState(0);
  const prev = () => {
    if (activePage > 0) {
      setActivePage(x => x - 1);
    }
  };
  const next = () => {
    if (activePage < pages) {
      setActivePage(x => x + 1);
    }
  };
  const hasPrev = () => {
    return activePage > 0;
  };
  const hasNext = () => {
    return activePage < pages;
  };
  const goTo = (page: number) => {
    setActivePage(page);
  };
  const minPage = Math.max(activePage - 5, 1);
  const maxPage = Math.min(activePage + 5, pages);
  const hasMoreLeft = minPage > 1;
  const hasMoreRight = maxPage < pages;
  const nodes = [...range(minPage, maxPage)].map<Button>((value) => {
    return {
      value,
      clickable: value !== activePage,
      active: value === activePage,
      previous: false,
      next: false,
    };
  });
  const buttons: Button[] = [{
    clickable: hasMoreLeft,
    active: false,
    value: minPage - 1,
    previous: true,
    next: false,
  }, ...nodes, {
    clickable: hasMoreRight,
    active: false,
    value: maxPage + 1,
    previous: false,
    next: true,
  }];
  return ({
    activePage,
    prev,
    next,
    hasPrev,
    hasNext,
    buttons,
    goTo,
    setPages,
  });
};
