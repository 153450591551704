import { ProductOrder } from "./productOrder.type";
import * as React from "react";

export const OrderFlowStatusComp = ({
                                      productOrderInfo,
                                    }: {
  productOrderInfo: ProductOrder;
}) => {
  switch (productOrderInfo.flow) {
    case "NEW": {
      return (<h5>
        <div className="badge badge-pill badge-secondary">{productOrderInfo.flow}</div>
      </h5>);
    }
    case "WAITING": {
      return (<h5>
        <div className="badge badge-pill badge-secondary">{productOrderInfo.flow}</div>
      </h5>);
    }

    case "STARTED": {
      return (<h5>
        <div className="badge badge-pill badge-primary">{productOrderInfo.flow}</div>
      </h5>);
    }
    case "NOTIFIED": {
      return (<h5>
        <div className="badge badge-pill badge-primary">{productOrderInfo.flow}</div>
      </h5>);
    }

    case "CHECKED": {
      return (<h5>
        <div className="badge badge-pill badge-info">{productOrderInfo.flow}</div>
      </h5>);
    }

    case "PACKED": {
      return (<h5>
        <div className="badge badge-pill" style={{ backgroundColor: "#f3b2c7", color: "#FFF" }}>{productOrderInfo.flow}</div>
      </h5>);
    }

    case "STOCKED": {
      return (<h5>
        <div className="badge badge-pill badge-success">{productOrderInfo.flow}</div>
      </h5>);
    }
    case "SHIPPED": {
      return (<h5>
        <div className="badge badge-pill badge-success">{productOrderInfo.flow}</div>
      </h5>);
    }

    case "HOLD": {
      return (<h5>
        <div className="badge badge-pill badge-dark">{productOrderInfo.flow}</div>
      </h5>);
    }

    case "OK_CANCELED":
    case "CANCELED": {
      return (<h5>
        <div className="badge badge-pill badge-lights">{productOrderInfo.flow}</div>
      </h5>);
    }

    case "REJECTED": {
      return (<h5>
        <div className="badge badge-pill badge-danger">{productOrderInfo.flow}</div>
      </h5>);
    }

    case "ERR_CANCELED":
    case "ERR_ADDR":
    case "ERR_PRODUCT":
    case "ERR_STORE":
    case "ERR_UNKNOWN":
    case "REJECTED":
    case "ERROR": {
      return (<h5>
        <div className="badge badge-pill badge-danger">{productOrderInfo.flow}</div>
      </h5>);
    }
  } // switch

  return (
    <div className="badge badge-danger">{(productOrderInfo.flow) || ""}</div>
  );
};
